export default function useCasesTagFilter() {
  const filterButtons = document.querySelectorAll(".filter-button");
  const useCaseItems = document.querySelectorAll(".use-case-item");

  filterButtons.forEach((button) => {
    button.addEventListener("click", () => {
      const filter = button.getAttribute("data-filter");

      useCaseItems.forEach((item) => {
        const itemTags = item.getAttribute("data-tags").split(",");

        if (filter !== "all" && !itemTags.includes(filter)) {
          item.style.opacity = "0";
          item.style.transform = "scale(0.9)";
          setTimeout(() => {
            item.style.display = "none";
          }, 200);
        } else {
          item.style.display = "block";
          setTimeout(() => {
            item.style.opacity = "1";
            item.style.transform = "scale(1)";
          }, 10);
        }
      });
    });
  });
}
